import * as React from "react"
import { graphql } from "gatsby"

import Layout from "components/layout"
import PageTitle from "components/PageTitle"
import ContactForm from "components/ContactForm"
import Seo from "components/seo"

// markup
const ContactPage = ({ data }) => {
  const { wpPage: page } = data;

  return (
    <Layout>
      <Seo
        title={ page.seo.title }
        description={ page.seo.metaDesc }
      />
        <PageTitle title="Contact"/>
        <ContactForm />
    </Layout>
  );
}

export const query = graphql`
  query ContactPageQuery {
    wpPage( title: { eq: "Contact"}){
      seo{
        title
        metaDesc
      }
    }
  }
`

export default ContactPage
