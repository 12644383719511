import React from 'react'
import Heading from 'components/Heading'

import {pageTitleSection, resinText} from './pageTitle.module.css'

function PageTitle({title}) {
    return (
        <section className={pageTitleSection}>
            <Heading level="h1">{title}</Heading>

            <svg className={resinText} width="1919" height="307" viewBox="0 0 1919 307" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1.37733 148.995C1.37733 99.3298 1.37733 55.1832 0 12.4162H136.356C137.733 23.4529 140.488 73.1178 140.488 100.709C162.525 42.767 216.241 1.37958 301.636 0V132.44C201.09 129.681 140.488 157.272 140.488 295.23V514.584H1.37733V148.995ZM437.991 293.851C437.991 357.312 469.67 418.013 538.537 418.013C596.385 418.013 612.913 394.56 626.686 364.209H765.796C747.891 426.291 692.798 527 535.782 527C370.502 527 298.881 402.838 298.881 270.398C298.881 113.126 380.143 0 541.291 0C713.458 0 772.683 125.542 772.683 251.084C772.683 267.639 772.683 278.675 771.306 293.851H437.991ZM632.195 208.317C630.818 150.374 607.403 100.709 539.914 100.709C473.802 100.709 446.255 146.236 440.746 208.317H632.195ZM921.435 364.209C932.453 404.217 964.132 426.291 1016.47 426.291C1067.43 426.291 1086.71 408.356 1086.71 378.005C1086.71 346.275 1064.68 332.479 993.056 315.924C829.154 274.537 802.984 223.492 802.984 153.133C802.984 78.6361 855.323 0 1006.83 0C1159.71 0 1210.67 82.7749 1214.81 151.754H1082.58C1078.45 133.819 1066.05 97.9503 999.943 97.9503C954.491 97.9503 939.34 118.644 939.34 140.717C939.34 165.55 954.491 177.966 1037.13 197.28C1205.17 235.908 1228.58 293.851 1228.58 368.348C1228.58 451.123 1165.22 527 1012.34 527C858.078 527 796.098 453.882 785.079 364.209H921.435ZM1271.28 12.4162H1410.39V515.963H1271.28V12.4162ZM1468.24 139.338C1468.24 96.5707 1468.24 49.6649 1466.86 12.4162H1601.84C1604.59 24.8324 1605.97 60.7016 1607.35 75.877C1623.87 44.1466 1665.19 0 1753.34 0C1853.89 0 1920 68.9791 1920 194.521V514.584H1780.89V211.076C1780.89 154.513 1761.61 113.126 1701 113.126C1641.78 113.126 1608.72 146.236 1608.72 234.529V514.584H1469.61V139.338H1468.24Z" fill="url(#paint0_linear)"/>
                <defs>
                <linearGradient id="paint0_linear" x1="960" y1="0" x2="960" y2="353" gradientUnits="userSpaceOnUse">
                <stop stopColor="#3B5996"/>
                <stop offset="1" stopColor="#27398D"/>
                </linearGradient>
                </defs>
            </svg>

        </section>
    )
}

export default PageTitle