module.exports = {
    title: `Resin Marketing`,
    keywords: [
        "Resin",
        "Marketing",
    ],
    description: `Marketing that sticks`,
    author: "@codeandcreate",
    siteUrl: `https://resin.marketing`,
    cmsUrl: `https://cms.resin.marketing/graphql`,
    trackingId: "UA-XXXXXXXX-X",
    defaultLang: "en",
  };
